import { useEffect, useState } from "react";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { AxiosRequestConfig } from "axios";
import {
  getStaffNotification,
  GetStaffNotificationRequestType,
  PatchEditNotificationPreferenceRequest,
  PatchEditPreferenceNotification,
} from "service/apis/StaffDetailsapi";
import { Spinner } from "components";
import { toast } from "sonner";
import { useNavigate } from "react-router-dom";
import Alert from "models/Alert/alert";

export default function NotificationPreferences() {
  const [preferences, setPreferences] = useState<{
    email: boolean;
    whatsapp: boolean;
    "In-app notification": boolean;
  }>({
    email: false,
    whatsapp: false,
    "In-app notification": true,
  });
  const [loading, setLoading] = useState(false);
  const [editing, setEditing] = useState(false);

  const navigate = useNavigate()

  const channels = [
    { id: "in-app", label: "In-app notification" },
    { id: "email", label: "Email" },
    { id: "whatsapp", label: "Whatsapp" },
  ];

  const toggleChannel = (channelKey: "email" | "whatsapp") => {
    setEditing(true);
    setPreferences((prev) => ({
      ...prev,
      [channelKey]: !prev[channelKey],
    }));
  };

  const removeChannel = (channelId: string, e: React.MouseEvent) => {
    e.stopPropagation();
    setEditing(true);
    setPreferences((prev) => ({
      ...prev,
      [channelId as keyof typeof preferences]: false,
    }));
  };

  const handleEditPrefrence = async () => {
    setEditing(false);
    setLoading(true);
    const auth = localStorage.getItem("authToken");
    const is_owner = localStorage.getItem("is_owner");
    const staff_id = localStorage.getItem("staff_id");
    if (auth === null) return;
    const authToken = JSON.parse(auth);
    const payload: AxiosRequestConfig<PatchEditNotificationPreferenceRequest> =
      {
        headers: { Authorization: `Bearer ${authToken}` },
        data: {
          staff_id: is_owner === "true" ? "0" : (staff_id as string),
          email: preferences.email,
          whatsapp: preferences.whatsapp,
        },
      };
    try {
      await PatchEditPreferenceNotification(payload);
      toast.success("Notification preferences updated successfully");
      setLoading(false);
    } catch (error) {
      setLoading(false);
      // Error handling
    }
  };

  const fetchPrefrences = async () => {
    setEditing(false);
    setLoading(true);
    const auth = localStorage.getItem("authToken");
    const is_owner = localStorage.getItem("is_owner");
    const staff_id = localStorage.getItem("staff_id");
    if (auth === null) return;
    const authToken = JSON.parse(auth);
    const payload: AxiosRequestConfig<GetStaffNotificationRequestType> = {
      headers: { Authorization: `Bearer ${authToken}` },
      params: {
        staff_id: is_owner === "true" ? "0" : (staff_id as string),
      },
    };
    try {
      const response = await getStaffNotification(payload);
      if (response.data) {
        setPreferences({
          email: response?.data?.notif_preferance?.email as boolean,
          whatsapp: response?.data?.notif_preferance?.whatsapp as boolean,
          "In-app notification": true,
        });
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      // Error handling
    }
  };

  useEffect(() => {
    fetchPrefrences();
  }, []);

  return (
    <>
      {loading && <Spinner />}
      <Card className="w-full max-w-lg border-none shadow-none">
        <CardHeader>
          <CardTitle className="text-sm text-black-label leading-[19.2px] font-medium">
            Notification preferences
          </CardTitle>
          <p className="text-xs text-[#999999] leading-[16.8px] font-normal">
            Select channel(s) on which you wish to receive notifications.
          </p>
        </CardHeader>
        <CardContent className="space-y-6">
          <div className="flex flex-wrap gap-2">
            {channels.map((channel) => {
              const isSelected =
                preferences[channel.id as keyof typeof preferences];
              return (
                <button
                  key={channel.id}
                  onClick={() =>
                    toggleChannel(channel.id as "email" | "whatsapp")
                  }
                  disabled={channel.id === "in-app"}
                  className={`
                    flex items-center gap-2 px-[18px] py-1.5 rounded-lg cursor-pointer
                    transition-colors duration-200
                    ${
                      isSelected || channel.id === "in-app"
                        ? "bg-[#E1EBFC] text-blue-700"
                        : "bg-[#F9F9F9] hover:bg-gray-200"
                    }
                    ${channel.id === "in-app" && "cursor-not-allowed"}
                  `}
                >
                  <span className="text-sm text-blue-700 leading-[19.2px] font-normal">
                    {channel.label}
                  </span>
                  {(isSelected || channel.id === "in-app") && (
                    <button
                      className="w-4 h-4 text-blue-700 hover:text-blue-900"
                      onClick={(e) => removeChannel(channel.id, e)}
                    >
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8 16C3.58698 16 0 12.413 0 8C0 3.58698 3.58698 0 8 0C12.413 0 16 3.58698 16 8C16 12.413 12.413 16 8 16ZM8 1.11628C4.20465 1.11628 1.11628 4.20465 1.11628 8C1.11628 11.7953 4.20465 14.8837 8 14.8837C11.7953 14.8837 14.8837 11.7953 14.8837 8C14.8837 4.20465 11.7953 1.11628 8 1.11628Z"
                          fill="#165FE3"
                        />
                        <path
                          d="M5.89222 10.6624C5.75082 10.6624 5.60943 10.6104 5.4978 10.4987C5.28198 10.2829 5.28198 9.92571 5.4978 9.70989L9.70989 5.4978C9.9257 5.28198 10.2829 5.28198 10.4987 5.4978C10.7145 5.71361 10.7145 6.07082 10.4987 6.28663L6.28663 10.4987C6.18245 10.6104 6.03361 10.6624 5.89222 10.6624Z"
                          fill="#165FE3"
                        />
                        <path
                          d="M10.1043 10.6624C9.96291 10.6624 9.82152 10.6104 9.70989 10.4987L5.4978 6.28663C5.28198 6.07082 5.28198 5.71361 5.4978 5.4978C5.71361 5.28198 6.07082 5.28198 6.28663 5.4978L10.4987 9.70989C10.7145 9.92571 10.7145 10.2829 10.4987 10.4987C10.3871 10.6104 10.2457 10.6624 10.1043 10.6624Z"
                          fill="#165FE3"
                        />
                      </svg>
                    </button>
                  )}
                </button>
              );
            })}
          </div>
          <div className="flex justify-start gap-3">
            <Alert actionText="changes" handleSubmit={()=>navigate("/")} actionButtonText="Confirm">
              <Button
                className="h-[42px] bg-transparent rounded-lg border text-blue-700 border-blue-700 max-w-[80px] w-full"
                variant="outline"
              >
                Cancel
              </Button>
            </Alert>
            <Button
              disabled={!editing}
              onClick={handleEditPrefrence}
              className="h-[42px] bg-blue-700 border rounded-lg border-blue-700 text-white-A700 max-w-[80px] w-full"
            >
              Save
            </Button>
          </div>
        </CardContent>
      </Card>
    </>
  );
}
