
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    AlertDialogPortal,
    AlertDialogTitle,
    AlertDialogTrigger,
} from "@/components/ui/alert-dialog";

interface AlertProps {
    children: React.ReactNode;
    handleSubmit: () => void;
    actionText: string;
    actionButtonText: string;
}

const Alert = ({ children, handleSubmit, actionText, actionButtonText = "Confirm" }: AlertProps) => {
    return (
        <AlertDialog>
            <AlertDialogTrigger>
                {children}
            </AlertDialogTrigger>
            <AlertDialogPortal>
                <AlertDialogOverlay className=" bg-black-900 bg-opacity-60 animate-overlayShow fixed inset-0" />
                <AlertDialogContent className="bg-white-A700 rounded-lg p-0 max-w-2xl animate-contentShow">
                    <AlertDialogHeader className="flex text-left gap-4 pt-8 pb-4 px-10 ">
                        <AlertDialogTitle className="font-bold text-xl leading-7 tracking-[2%] text-black-label">
                            Do you want to{" "}
                            <span className="font-semibold text-blue-700">
                                discard
                            </span>{" "}
                            {actionText}?
                        </AlertDialogTitle>
                        <AlertDialogDescription className="font-medium text-base leading-6 tracking-[2%] text-[#7E7E7E] ">
                            This action cannot be undone. This will permanently cancel
                            the ongoing progress.
                        </AlertDialogDescription>
                    </AlertDialogHeader>
                    <AlertDialogFooter className="flex justify-end items-center gap-2.5 py-5 px-10 flex-row rounded-b-lg bg-gray-button-gray-lite">
                        <AlertDialogCancel
                            className=" m-0 rounded-lg border-blue-700 border py-[11px] px-4 text-blue-700"                            
                        >
                            Close
                        </AlertDialogCancel>
                        <AlertDialogAction
                            className="rounded-lg border-blue-700 py-[11px] px-4 bg-blue-700 border text-white-A700"                            
                            onClick={handleSubmit}
                        >
                            {actionButtonText}
                        </AlertDialogAction>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogPortal>
        </AlertDialog>
    )
}

export default Alert;