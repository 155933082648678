import NavbarWithActions from "components/Navbar/navbarwithactions";
import { Outlet } from "react-router-dom";

const Profile = () => {
  return (
    <div className="flex h-full w-full flex-col flex-grow gap-2.5 font-inter">
      <NavbarWithActions header="My profile" />
      <div className="bg-white-A700 overflow-hidden flex-1 p-4 h-full flex-grow rounded-lg">
        <Outlet />
      </div>
    </div>
  );
};

export default Profile;
